.carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 56.25vw;
  z-index: 9999;
}

.carousel-container .headline {
  pointer-events: none;
}

.main-carousel {
  width: 100vw;
  height: 56.25vw;
  position: relative;
  top: 0;
  left: 0;
}

.carousel-cell {
  width: 100vw;
  height: 56.25vw;
  display: block;
  position: absolute;
}

.carousel-cell img,
.carousel-cell video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (min-width: 640px) {
  .carousel-container {
    height: 56.25vw;
  }

  .main-carousel {
    height: 56.25vw;
  }
  
  .carousel-cell {
    height: 56.25vw;
  }
}